import React, { ReactNode } from 'react'
import { createRoot } from 'react-dom/client'
import { RootCss, shadowOuterStyle } from '../components/root-css'
import { ResetCss } from '../components/reset-css'
import { styled } from 'styled-components'
import shadowRoot from 'react-shadow/styled-components'
import { ApolloProvider } from '@apollo/client'
import { apolloClient } from '../apollo-client'

const Outer = styled(shadowRoot.div!)`
  ${shadowOuterStyle}
`

export const mount = (rootElementSelector: string, children: ReactNode) => {
  document.addEventListener('DOMContentLoaded', () => {
    const rootElement = document.querySelector(rootElementSelector)
    if(rootElement){
      const root = createRoot(rootElement)
      root.render(
        <ApolloProvider client={apolloClient}>
          <Outer>
            <ResetCss />
            <RootCss />
            {children}
          </Outer>
        </ApolloProvider>
      )
    }
  })
}