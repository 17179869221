import * as React from "react";
import type { SVGProps } from "react";
const SvgGroupIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={95}
    height={95}
    fill="none"
    viewBox="0 0 95 95"
    {...props}
  >
    <mask
      id="1-a"
      width={95}
      height={95}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "luminance",
      }}
    >
      <path fill="#fff" d="M95 0H0v95h95z" />
    </mask>
    <g mask="url(#1-a)">
      <path
        stroke="#0065AC"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M47.41 59.375c4.532 0 8.206-3.655 8.206-8.164s-3.674-8.164-8.205-8.164-8.205 3.655-8.205 8.164 3.673 8.164 8.205 8.164M57.854 70.964v22.551M37.11 93.515V70.964M13.36 93.515V70.964M47.411 93.482v-8.676"
      />
      <circle cx={57.853} cy={84.806} r={1.484} fill="#0065AC" />
      <path
        stroke="#0065AC"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M47.5 59.375h-4.453c-6.134 0-13.36.835-13.36 13.556v7.47c0 3.255 2.657 5.89 5.938 5.89h1.484M23.66 59.375c4.532 0 8.206-3.655 8.206-8.164s-3.674-8.164-8.205-8.164-8.205 3.655-8.205 8.164 3.674 8.164 8.205 8.164M23.75 59.375h-4.453c-6.134 0-13.36.835-13.36 13.556v7.47c0 3.255 2.658 5.89 5.938 5.89h1.484M81.64 93.515V70.964M71.339 59.375c4.531 0 8.205-3.655 8.205-8.164s-3.674-8.164-8.205-8.164-8.205 3.655-8.205 8.164 3.673 8.164 8.205 8.164"
      />
      <path
        stroke="#0065AC"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M71.25 59.375h4.453c6.134 0 13.36.835 13.36 13.556v7.47c0 3.255-2.657 5.89-5.938 5.89h-1.484M47.5 59.375h4.453c6.134 0 13.36.835 13.36 13.556v7.47c0 3.255-2.657 5.89-5.938 5.89h-1.484M92.031 7.422v17.812a5.936 5.936 0 0 1-5.937 5.938h-7.422l-7.422 7.422v-7.422H50.469L47.5 38.594l-4.453-7.422H23.75v7.422l-7.422-7.422H8.906a5.936 5.936 0 0 1-5.937-5.938V7.422a5.936 5.936 0 0 1 5.937-5.938h77.188a5.936 5.936 0 0 1 5.937 5.938"
      />
      <path
        stroke="#0065AC"
        strokeLinecap="round"
        strokeWidth={2}
        d="M20 12h54M20 20h40"
      />
    </g>
  </svg>
);
export default SvgGroupIcon;
