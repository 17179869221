import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Button } from '../components/button'
import { useSettings } from '../hooks/use-settings'
import { Mbr } from '../components/br'
import { pageMaxWidth } from '../styles/page-max-width'
import SvgLogoSymbol from '../generated/svg/logo-symbol'

const Outer = styled.div`
background-color: #E9EAEE;
position: relative;
`

const Bg = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  svg {
    display: block;
    width: 101.6875rem;
    height: auto;
    position: absolute;
    left: 60%;
    transform: translateY(50%);
    bottom: 0;
  }
`

const Inner = styled.div`
  ${pageMaxWidth}
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5.5625rem;
  padding-bottom: 6.25rem;
  position: relative;
  z-index: 2;
  @media (max-width: 40rem){
    padding-top: 4.8125rem;
    padding-bottom: 5rem;
    padding-left: var(--page-inside-margin);
    padding-right: var(--page-inside-margin);
  }
`

const Head = styled.div`
  font-size: 2rem;
  line-height: 1.65625;
  font-family: var(--serif-font);
  font-weight: 500;
  @media (max-width: 40rem){
    font-size: 1.375rem;
    line-height: 1.2045454545454546;
  }
`

const Text1 = styled.div`
  font-size: 1.25rem;
  line-height: 1.5;
  letter-spacing: 0.02em;
  margin-top: 0.625rem;
  font-weight: 900;
  color: var(--blue);
  @media (max-width: 40rem){
    margin-top: 0.875rem;
    font-size: 1.25rem;
    line-height: 1.375;
    text-align: center;
  }
`

const Container = styled.div`
  display: flex;
  gap: 1.875rem;
  margin-top: 3rem;
  @media (max-width: 50rem){
    flex-direction: column;
    width: 100%;
  }
  @media (max-width: 40rem){
    margin-top: 1.125rem;
  }
`

const Box = styled.div`
  padding: 1.25rem 2.5rem;
  border: 1px solid var(--blue);
  border-radius: 0.5rem;
  display: flex;
  gap: 1.3125rem;
  align-items: flex-start;
  @media (max-width: 50rem){
    flex-direction: column;
    align-items: center;
    gap: 0;
    width: 100%;
    box-sizing: border-box;
  }
  @media (max-width: 40rem){
    padding: 1rem 2rem;
  }
`

const Text2 = styled.div`
  font-size: 1.0625rem;
line-height: 2;
letter-spacing: 0.13em;
padding: 0 1.125rem;
  background-color: var(--blue);
  color: white;
  font-weight: 500;
@media (max-width: 40rem){
  font-size: 0.812498927116394rem;
line-height: 2;
letter-spacing: 0.13em;
padding: 0 0.875rem;
}
`

const BoxInner = styled.div`
@media (max-width: 50rem){
  display: contents;
}
`

const Text3 = styled.div`
font-size: 0.9375rem;
line-height: 2.2666666666666666;
letter-spacing: 0.02em;
font-weight: 500;
margin-top: calc((1lh - 1em) / -2);
@media (max-width: 50rem){
  margin-top: 0.5rem;
}
@media (max-width: 40rem){
  margin-top: 0;
  font-size: 1.0934579372406006rem;
line-height: 1.6250000272551155;
letter-spacing: 0.02em;
}
`

const Text4 = styled.div`
font-size: 1.625rem;
line-height: 1.3076923076923077;
letter-spacing: 0em;
font-weight: 900;
color: var(--blue);
margin-top: -0.25rem;
@media (max-width: 40rem){
  font-size: 1.6401869058609009rem;
line-height: 1.2083333060782178;
letter-spacing: 0em;
}
`

const Text5 = styled.div`
font-size: 0.8125rem;
line-height: 1.6923076923076923;
letter-spacing: 0.02em;
margin-top: 0.625rem;
font-weight: 400;
@media (max-width: 50rem){
  text-align: center;
}
@media (max-width: 40rem){
  font-size: 0.8125rem;
line-height: 1.4615384615384615;
letter-spacing: 0.02em;
margin-top: 0.5625rem;
}
`

const Card = styled.div`
filter: drop-shadow(0 0.1875rem 0.1875rem color-mix(in srgb, #000000 40%, transparent));
  @media (max-width: 50rem){
    order: -1;
  }
  img {
    display: block;
    height: 10.375rem;
    width: auto;
    @media (max-width: 50rem){
      height: auto;
      width: 20rem;
      max-width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
  }
`

const Button2 = styled(Button)`
  @media (max-width: 40rem){
    margin-top: 2.125rem;
  }
`

type DinersSectionProps = {

}
export const DinersSection: FunctionComponent<DinersSectionProps> = () => {
  const {themeRoot} = useSettings()
  return <Outer id="diners">
    <Bg>
      <SvgLogoSymbol />
    </Bg>
    <Inner>
      <Head>
        ダイナースクラブカード
      </Head>
      <Text1>
        ダイナースクラブカード <Mbr />新規入会キャンペーン
      </Text1>
      <Container>
        <Box>
          <Text2>
            特典
          </Text2>
          <BoxInner>
            <Text3>
              本会員・家族会員ともに…
            </Text3>
            <Text4>
              初年度年会費無料
            </Text4>
            <Text4 style={{textAlign: 'center'}}>
              ＋
            </Text4>
            <Text3>
              入会後3ヵ月以内に20万円以上のカードご利用で
            </Text3>
            <Text4>
              10,000円キャッシュバック
            </Text4>
            <Text5>
              2025年 4月30日お申し込み分<br />
              (2025年5月30日(金)カード発行分)まで
            </Text5>
          </BoxInner>
        </Box>
        <Card>
          <img src={`${themeRoot}/images/card-face.webp`} />
        </Card>
      </Container>
      <Button2 href="https://www.diners.co.jp/ja/entry_form/lp/ptnr_rd/index.html?qsrf=furutoki_web" target="_blank">
        詳しくはこちら
      </Button2>
    </Inner>
  </Outer>
}
