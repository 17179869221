import { css } from 'styled-components'

export const pageMaxWidth = css`
  max-width: var(--page-max-width);
  padding-left: var(--page-margin);
  padding-right: var(--page-margin);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
`