import React from 'react';
import { FunctionComponent } from 'react';
import { createGlobalStyle, css } from 'styled-components';

const Css = createGlobalStyle`
  :root, :host {
    --page-margin: 2rem;
    --page-inside-margin: 2rem;
    --page-max-width: 62.5rem;
    --side-margin: calc((100vw - min(100%, var(--page-max-width))) / 2);
    
    --blue: #0065AC;
    color: #3F3939;
    --serif-font: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
    font-family: "Noto Sans JP", sans-serif;
    @media (max-width: 75rem){
      --page-margin: 3rem
    }
    @media (max-width: 50rem){
      --page-margin: 1.25rem
    }
  }
  a {
    color: inherit;
    text-decoration: inherit;
  }
  :is(sup) {
    font-size: 0.625rem !important;
    vertical-align: super !important;
    white-space: nowrap !important;
  }
`

export const shadowOuterStyle = css`
  all: initial;
  line-height: 1;
  font-family: "游ゴシック体", "Yu Gothic", YuGothic, "Noto Sans JP", sans-serif;
`

export const RootCss: FunctionComponent = () => {
  return <>
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/swiper@11/swiper-bundle.min.css" />
    <Css />
  </>
}