import * as React from "react";
import type { SVGProps } from "react";
const SvgLogoSymbol = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={1627}
    height={1627}
    fill="none"
    viewBox="0 0 1627 1627"
    {...props}
  >
    <path
      fill="#fff"
      d="M813.5 0C363.923 0 0 364.366 0 813.5 0 1262.63 363.923 1627 813.5 1627c449.58 0 813.5-364.37 813.5-813.5C1627 364.366 1263.08 0 813.5 0M628.876 1294.59c-193.5-74.56-330.637-261.41-331.081-481.09.444-219.685 137.581-406.972 331.081-481.088zm369.248.44V332.412C1191.62 406.528 1329.2 593.815 1329.2 813.944c0 219.686-137.58 406.976-331.076 481.086"
    />
  </svg>
);
export default SvgLogoSymbol;
