import React, { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'
import { ModalProvider } from '../hooks/use-modal'

const Outer = styled.div`
  padding-top: var(--header-height);
`

type PageOuterProps = {
  children?: ReactNode
}
export const PageOuter: FunctionComponent<PageOuterProps> = ({children}) => {
  return <ModalProvider>
    <Outer>
      {children}
    </Outer>
  </ModalProvider>
}