import { ApolloClient, HttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client'

declare global {
  interface Window {
    apolloClient: ApolloClient<NormalizedCacheObject>
  }
}

if(!window.apolloClient){
  window.apolloClient = new ApolloClient({
    link: new HttpLink({
      uri: 'https://www.diners-furusato.jp/index.php?graphql',
      fetchOptions: {
        credentials: 'same-origin',
      },
    }),
    cache: new InMemoryCache()
  })
}

export const apolloClient = window.apolloClient