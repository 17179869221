import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useShopsQuery } from '../generated/graphql'
import { SvgLeftAngle, SvgRightAngle } from '../components/svg'
import { pageMaxWidth } from '../styles/page-max-width'
import { slideWidth } from '../styles/slide-width'
import classNames from 'classnames'
import { useSwiperClass } from '../hooks/use-swiper-class'
import { Button } from '../components/button'

const Outer = styled.div`
  background-color: #F4F4F5;
  overflow: clip;
  scroll-margin-top: var(--header-height);
`

const Inner = styled.div`
  ${pageMaxWidth}
  padding-bottom: 7.75rem;
  padding-top: 7rem;
  @media (max-width: 40rem){
    padding-top: 4.6875rem;
    padding-bottom: 4.875rem;
  }
`

const Head = styled.div`
  font-size: 2rem;
  line-height: 1.65625;
  letter-spacing: 0.02em;
  font-weight: 500;
  font-family: var(--serif-font);
  text-align: center;
  @media (max-width: 40rem){
    font-size: 1.1875rem;
    line-height: 1.394736842105263;
    letter-spacing: 0.02em;
  }
`

const SwiperOuter = styled.div`
  --slides-per-view: 2;
  --gap: 2rem;
  margin-top: 2.4375rem;
  position: relative;
  @media (max-width: 40rem){
    margin-top: 1.5rem;
  }
  @media (max-width: 40rem){
    margin-left: 1rem;
    margin-right: 1rem;
    --slides-per-view: 1;
    --gap: 1rem;
  }
  .swiper {
    overflow: visible;
  }
  .swiper-wrapper {
    align-items: stretch;
  }
  .swiper-slide {
    ${slideWidth}
    height: auto;
  }
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const ImageContainer = styled.a`
  display: block;
  overflow: hidden;
  img {
    display: block;
    width: 100%;
    height: auto;
    transition: transform 0.4s;
  }
  &:hover img {
    transform: scale(1.05);
  }
`

const CategoryList = styled.div`
  margin-top: 1.5rem;
  display: flex;
  @media (max-width: 40rem){
    margin-top: 1rem;
  }
`

const CategoryItem = styled.div`
  font-size: 0.875rem;
  letter-spacing: 0.02em;
  padding: 0.5rem 1rem;
  font-weight: 600;
  color: white;
  @media (max-width: 40rem){
    font-size: 0.875rem;
    line-height: 0.9313725062779018;
    letter-spacing: 0.02em;
    padding: 0.4375rem 1rem;
  }
`

const Area = styled.div`
  font-size: 0.875rem;
  line-height: 3;
  letter-spacing: 0.02em;
  @media (max-width: 40rem){
    font-size: 0.8149509429931641rem;
    line-height: 3;
    letter-spacing: 0.02em;
  }
`

const Name = styled.a`
  font-size: 1.125rem;
  line-height: 1.4444444444444444;
  letter-spacing: 0.02em;
  font-weight: 700;
  margin-top: 0.25rem;
  color: var(--blue);
  display: block;
  @media (max-width: 40rem){
    line-height: 1.513480305671692;
    letter-spacing: 0.02em;
  }
`

const Description = styled.div`
  font-size: 0.875rem;
  line-height: 1.6428571428571428;
  letter-spacing: 0.02em;
  font-weight: 300;
  margin-top: 0.6875rem;
  margin-bottom: auto;
  @media (max-width: 40rem){
    font-size: 0.875rem;
    line-height: 1.7857142857142858;
  }
`

const Controls = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
  box-sizing: border-box;
  
`

const ControlButton = styled.div`
  pointer-events: all;
  cursor: pointer;
  color: var(--blue);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  box-shadow: 0.1875rem 0.1875rem 0.4375rem color-mix(in , #484848 25%, transparent);
  aspect-ratio: 1 / 1;
  border-radius: 100rem;
  height: 3.5625rem;
  &:nth-child(1){
    transform: translateX(-2rem);
  }
  &:nth-child(2){
    transform: translateX(2rem);
  }
  @media (max-width: 40rem){
    height: 2.5rem;
  }
  svg {
    display: block;
    height: 1.75rem;
    width: auto;
    @media (max-width: 40rem){
      height: 1.25rem;
    }
  }
`

type TopShopListSectionProps = {

}
export const TopShopListSection: FunctionComponent<TopShopListSectionProps> = () => {
  const shopsQuery = useShopsQuery()
  const {ref, slideNext, slidePrev, hasNext, hasPrev} = useSwiperClass()
  const [imgHeight, setImgHeight] = useState(0)
  const outerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const outer = outerRef.current
    if(outer){
      const listener = () => {
        const img = outer.querySelector('.swiper-slide img')
        if(img){
          const {height} = img.getBoundingClientRect()
          setImgHeight(height)
        }
      }
      listener()
      const observer = new ResizeObserver(listener)
      observer.observe(outer)
      return () => {
        observer.disconnect()
      }
    }
  })

  return <Outer ref={outerRef} id="shops">
    <Inner>
      <Head>
        参加店のご紹介
      </Head>
      <SwiperOuter>
        <Swiper
          loop
          slidesPerView="auto"
          onSwiper={ref}
        >
          {
            shopsQuery.data?.shops?.nodes.map(shop => {
              return <SwiperSlide key={shop.shopId}>
                <Item>
                  <ImageContainer href={`/shop/${shop.slug}`}>
                    <img src={shop.shopFields?.listImage?.node.mediaItemUrl ?? ''} />
                  </ImageContainer>
                  <CategoryList>
                    {
                      shop.genres?.nodes.map((genre, i) => {
                        return <CategoryItem key={i} style={{backgroundColor: genre.genreFields?.color ?? ''}} dangerouslySetInnerHTML={{__html: genre.name ?? ''}}></CategoryItem>
                      })
                    }
                  </CategoryList>
                  <Area dangerouslySetInnerHTML={{__html: shop.shopFields?.area ?? ''}}/>
                  <Name dangerouslySetInnerHTML={{__html: shop.title ?? ''}} href={`/shop/${shop.slug}`}/>
                  <Description dangerouslySetInnerHTML={{__html: shop.shopFields?.description ?? ''}}/>
                  <Button href={`/shop/${shop.slug}`}>
                    詳しくはこちら
                    <SvgRightAngle />
                  </Button>
                </Item>
              </SwiperSlide>
            })
          }
        </Swiper>
        <Controls style={{height: imgHeight}}>
          <ControlButton onClick={() => slidePrev()} className={classNames({disabled: !hasPrev})}>
            <SvgLeftAngle strokeWidth={2}/>
          </ControlButton>
          <ControlButton onClick={() => slideNext()} className={classNames({disabled: !hasNext})}>
            <SvgRightAngle strokeWidth={2}/>
          </ControlButton>
        </Controls>
      </SwiperOuter>
    </Inner>
  </Outer>
}