type Settings = {
  themeRoot: string
}
export const useSettings = (): Settings => {
  return Object.assign({}, ...Array.from(document.querySelectorAll('.settings')).map(el => {
    if(el.textContent){
      return JSON.parse(el.textContent)
    } else {
      return {}
    }
  }))
}