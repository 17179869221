import React, { ComponentProps, FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'
import { SvgRightAngle } from './svg'

export const Outer = styled.a`
  font-size: 1rem;
  line-height: 1.2777777777777777;
  letter-spacing: 0.02em;
  margin-top: auto;
  font-weight: 500;
  color: var(--blue);
  border: 1px solid currentColor;
  background-color: white;
  padding: 1.125rem 1.5rem;
  max-width: 22rem;
  text-align: center;
  position: relative;
  align-self: start;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  width: 100%;
  margin-top: 2.875rem;
  display: block;
  @media (max-width: 40rem){
    font-size: 1rem;
    line-height: 1.3388479948043823;
    letter-spacing: 0.02em;
    padding: 0.9375rem 1.5rem;
    max-width: 22rem;
  }

  svg {
    display: block;
    height: 1rem;
    width: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    right: 2rem;
    transition: transform 0.4s;
  }
  &:hover svg {
    transform: translateX(0.5rem);
  }
`

type ButtonProps = {
  children?: ReactNode
} & ComponentProps<'a'>
export const Button: FunctionComponent<ButtonProps> = ({children, ...props}) => {
  return <Outer {...props}>
    {children}
    <SvgRightAngle strokeWidth={1.5}/>
  </Outer>
}
