import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import SvgGroupIcon from '../generated/svg/group-icon'
import { Button } from '../components/button'
import { pageMaxWidth } from '../styles/page-max-width'
import { Mbr } from '../components/br'

const Outer = styled.div`
  scroll-margin-top: var(--header-height);
`

const Inner = styled.div`
  ${pageMaxWidth}
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4.25rem;
  padding-bottom: 5rem;
  @media (max-width: 40rem){
  }
`

const Head = styled.div`
  font-size: 2rem;
  line-height: 1.65625;
  letter-spacing: 0.02em;
  font-weight: 500;
  font-family: var(--serif-font);
  color: var(--blue);
  border-bottom: 1px solid currentColor;
  position: relative;
  @media (max-width: 40rem){
    padding-bottom: 0.6875rem;
  }
  &::after {
    content: '';
    display: block;
    border-bottom: 1px solid currentColor;
    background-color: #fff;
    width: 2rem;
    aspect-ratio: 1 / 1;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: 0;
    transform: translateY(50%) rotate(45deg);
    position: absolute;
    @media (max-width: 40rem){
      width: 1.25rem;
    }
  }
  @media (max-width: 40rem){
    font-size: 1.375rem;
    line-height: 1.2045454545454546;
    letter-spacing: 0.02em;
  }
  div {
    position: relative;
    z-index: 1;
  }
`

const Icon = styled.div`
  margin-top: 3rem;
  @media (max-width: 40rem){
    margin-top: 2.5rem;
  }
  svg {
    height: 5.9375rem;
    width: auto;
    display: block;
    @media (max-width: 40rem){
      height: 4.6875rem;
    }
  }
`

const Text = styled.div`
  line-height: 1.875;
  letter-spacing: 0.02em;
  font-weight: 500;
  margin-top: 0.6875rem;
  text-align: center;
  @media (max-width: 40rem){
    font-size: 0.875rem;
    line-height: 1.7857142857142858;
    letter-spacing: 0.02em;
    margin-top: 1.1875rem;
  }
`

const Button2 = styled(Button)`
  @media (max-width: 40rem){
    margin-top: 1.5625rem;
  }
`

type TopQuestionnaireSectionProps = {

}
export const TopQuestionnaireSection: FunctionComponent<TopQuestionnaireSectionProps> = () => {
  return <Outer id="questionnaire">
    <Inner>
      <Head>
        <div>
          皆様の声をお届けください
        </div>
      </Head>
      <Icon>
        <SvgGroupIcon />
      </Icon>
      <Text>
        皆様のふるさとのおすすめ食材や、<br />
        忘れられない思い出の地域などの情報を<Mbr />お寄せください！
      </Text>
      <Button2 href="https://www.diners.co.jp/ja/4z7czfm9uh.html" target="_blank">
        詳しくはこちら
      </Button2>
    </Inner>
  </Outer>
}
