import React, { FunctionComponent, ReactNode, forwardRef } from 'react'
import styled from 'styled-components'

const Outer = styled.div`
  display: flex;
  flex-direction: column;
`

const Inner = styled.div`
  margin: calc((1lh - 1em) / -2) 0;
`

type TextProps = {
  children?: ReactNode
}
export const Text: FunctionComponent<TextProps> = forwardRef<HTMLDivElement, TextProps>((props, ref) => {
  const {children, ...outerProps} = props
  return <Outer ref={ref} {...outerProps}>
      <Inner>
        {children}
      </Inner>
    </Outer>
})